/* Invoice Management Overall Layout */
.im-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.im-main-content {
  display: flex;
  flex-direction: column;
  padding-left: 270px;
  padding-top: 70px;
  padding-right: 20px;
  padding-bottom: 60px;
  min-height: calc(100vh - 130px);
  box-sizing: border-box;
  margin: 20px;
  overflow-y: auto;
}

.im-content-area {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 20px;
}

.im-recent-invoices {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  grid-column: 1 / span 7;
  grid-row: 1 / span 2;
  height: auto;
  overflow-y: auto;
}

/* Header with Search Bar and Create Button */
.im-recent-invoices-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.im-search-bar {
  width: 250px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 10px;
}

/* Invoice Table Styles */
.im-invoices-table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Arial', sans-serif;
}

.im-invoices-table th,
.im-invoices-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
}

.im-invoices-table th {
  background-color: #343a40;
  color: white;
}

.im-invoices-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.im-invoices-table tr:hover {
  background-color: #ddd;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .im-content-area {
    grid-template-columns: 1fr;
  }

  .im-recent-invoices {
    grid-column: 1 / span 1;
  }
}

/* Style for the delete button */
.im-delete-btn {
  margin-left: 10px;
  padding: 8px 12px;
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.im-delete-btn:hover {
  background-color: #ff7875;
}

/* Adjust table styles if necessary */
.im-invoices-table th,
.im-invoices-table td {
  padding: 12px;
  text-align: left;
}

.im-invoices-table th input[type='checkbox'],
.im-invoices-table td input[type='checkbox'] {
  margin: 0;
}