/* src/components/Sidebar.css */

.sidebar {
  width: 250px;
  background-color: #343a40;
  color: #fff;
  height: calc(100vh - 60px); /* Adjust height considering the header height */
  position: fixed;
  top: 60px; /* Adjust top position considering the header height */
  left: 0;
  overflow-y: auto; /* Add scroll if content overflows */
  transition: transform 0.3s ease-in-out;
  z-index: 999; /* Ensure the sidebar is above other elements */
  cursor: pointer;
  padding: 0; /* Remove padding here */
  list-style: none; /* Remove bullet points */
}

.sidebar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px; /* Add margin from the top */
}

.sidebar a {
  color: white; /* Set link color to black (or your desired color) */
  text-decoration: none; /* Remove underline from links */
  font-weight: normal;
}

.sidebar a:hover {
  color: #007bff; /* Add your preferred hover color */
}

.sidebar a:visited {
  color: #ffffff; /* Ensure visited links remain black */
}

.sidebar h2 {
  color: #fff;
  margin-bottom: 10px;
  text-align: center;
}

.sidebar hr {
  border-color: #495057;
  margin-bottom: 10px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li, .coming-soon {
  padding: 0px; /* Uniform padding, adjust the horizontal padding as needed */
  margin-bottom: 5px; /* Adds spacing between elements */
  background-color: transparent; /* Resets any bg color */
  min-height: 40px; /* Ensures all items have the same height */
  display: flex; /* Makes it easier to center the text vertically */
  align-items: center; /* Aligns text vertically */
  justify-content: center; /* This centers the text horizontally */
}

.sidebar ul li:hover {
  background-color: #495057;
}

.sidebar-toggle {
  display: none;
  position: fixed;
  top: 70px; /* Position it below the header */
  left: 0; /* Align it with the left edge */
  background-color: #343a40;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1001;
  border-radius: 0; /* Sharp edges */
}

.close-btn {
  display: none; /* Hide by default */
  background-color: #343a40;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  border-radius: 0; /* Sharp edges */
  margin-bottom: 10px;
}

.full-width-link {
  display: block;  /* Makes the link fill the li */
  width: 100%;     /* Ensures it takes the full width of the li */
  padding: 10px;   /* Adjust padding as needed */
  color: inherit;  /* Inherits text color from the parent element */
  text-decoration: none; /* Removes underline from links */
  box-sizing: border-box; /* Includes padding in width calculation */
}

.full-width-link:hover {
  background-color: #495057; /* Background color on hover */
}

.coming-soon {
  position: relative;
  color: #ccc;  /* Greyed out to indicate it's inactive */
  cursor: help;  /* Changes cursor to indicate help/info available */
}

.coming-soon:hover::after {
  content: "Coming Soon";
  position: absolute;
  top: 100%;
  left: 50%;  /* Center tooltip horizontally */
  transform: translateX(-50%);  /* Adjust position to center */
  white-space: nowrap;
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}


@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%);
    height: calc(100vh - 60px); /* Make the sidebar take full height minus header */
    width: 100%; /* Make the sidebar take full width */
    top: 60px; /* Adjust top position considering the header height */
    left: 0; /* Reset left position */
    position: fixed; /* Fix the sidebar position */
    z-index: 1000; /* Ensure it is above other elements */
  }

  .sidebar.open {
    transform: translateY(10px); /* Move the sidebar slightly below the header */
  }

  .sidebar-toggle {
    display: block;
  }

  .sidebar.open .close-btn {
    display: block;
  }
}
