/* ClientDirectory.css */

/* Container for the Client Directory */
.cd-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  /* Main content area styling */
  .cd-main-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    margin-top: 60px;
    padding-bottom: 60px;
  }
  
  /* Content area styling */
  .cd-content-area {
    margin-left: 250px;
    flex: 1;
    padding: 20px;
  }
  
  /* Styles for the client list section */
  .cd-recent-clients {
    background-color: #fff;
    padding: 20px;
    margin-top: 10px;
    margin-left: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    width: calc(100% - 50px);
  }
  
  /* Header for the Client list section with search bar and delete button */
  .cd-recent-clients-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .cd-search-bar {
    width: 250px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 10px;
  }
  
  .cd-case-row-delete {
    background-color: #f0512b;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 5px;
  }
  
  .cd-case-row-delete:hover {
    background-color: #e6451c;
  }
  
  /* Table styling */
  .cd-clients-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .cd-clients-table th, .cd-clients-table td {
    padding: 8px 15px;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid #ddd;
  }
  
  .cd-clients-table th {
    background-color: #343a40;
    color: white;
  }
  
  .cd-clients-table td {
    background-color: #f2f2f2;
  }
  
  .cd-clients-table tr:hover {
    background-color: #ddd;
  }
  
  /* Style for the checkbox column to ensure it does not offset other content */
  .cd-clients-table th:first-child,
  .cd-clients-table td:first-child {
    text-align: left;
    width: 50px;
  }
  
  /* Responsive adjustments for smaller screens */
  @media (max-width: 768px) {
    .cd-content-area {
      margin-left: 0;
      margin-top: 60px;
    }
  
    .cd-recent-clients {
      margin-left: 10px;
      padding: 15px;
    }
  
    .cd-recent-clients-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .cd-search-bar {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .cd-case-row-delete {
      width: 100%;
      text-align: center;
    }
  }
  