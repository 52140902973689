.tmd_modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.tmd_modal_content {
    position: relative;
    background: white;
    padding: 20px;
    padding-bottom: 40px; /* Increased bottom padding for better visibility */
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 50%;
    max-width: 500px;
    max-height: 80vh; /* Limiting the height to 80% of the viewport height */
    overflow-y: auto; /* Makes content scrollable if it exceeds the max height */
    z-index: 1010; /* Ensure it's above most other content, adjust as necessary */
}

.tmd_modal_close_button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    border: 1px solid black;  /* Black square border */
    background: none;
    color: black;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    cursor: pointer;
}

.tmd_modal_close_button:hover {
    background: none;  /* No change on hover */
    border: 1px solid black;  /* Maintain the border on hover */
}

.tmd_modal_input {
    display: block;
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 1rem;
}

.tmd_modal_input::placeholder {
    color: #808080;
}

.tmd_modal_submit_button {
    background-color: #f0512b;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    width: 30%;
    border-radius: 5px;
}

.tmd_modal_submit_button:hover {
    background-color: #e6451c;
}

.tmd_modal_blur_effect {
    filter: blur(4px);
}
