/* src/pages/NewClient.css */

.new-client {
    background-color: #fff;
    padding: 20px;
    margin-top: 10px;
    margin-left: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    width: calc(100% - 50px);
    box-sizing: border-box;
}

.new-client h3 {
    font-family: 'Arial', sans-serif;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: left; /* Align heading to the left */
}

.new-client-form {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 10px; /* Reduced space between form groups */
    align-items: flex-start; /* Align all form groups to the left */
}

.form-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%; /* Ensure the form group takes full width */
}

.form-group label {
    font-size: 14px;
    font-weight: bold;
    margin-right: 20px; /* Increased space between label and input */
    min-width: 150px; /* Ensures consistent label width */
    text-align: left; /* Aligns label text to the left */
}

.form-group input,
.form-group select {
    flex: 1;
    padding: 6px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-width: 300px; /* Limit the width of the input fields */
}

.form-group input[type="radio"] {
    margin-right: 2px;
    margin-left: 0px;
}

.form-group .radio-label {
    margin-right: 10px; /* Space between "Male" and "Female" labels */
    font-size: 14px;
    font-weight: normal;
}

.submit-button {
    background-color: #F0512A;
    color: white;
    padding: 8px 16px; /* Slightly increased padding for a bigger button */
    font-size: 14px; /* Increased font size */
    font-weight: bold; /* Bold text */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px; /* Space above the button */
    width: auto; /* Ensure the button width is just enough for the text */
    display: block; /* Ensures the button is still block-level if needed */
    text-align: center; /* Centers the text */
}

.submit-button:hover {
    background-color: #f0a500;
}

.nc-save-btn {
    background-color: #F0512A;
    color: white;
    padding: 8px 16px; /* Slightly increased padding for a bigger button */
    font-size: 14px; /* Increased font size */
    font-weight: bold; /* Bold text */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px; /* Space above the button */
    width: auto; /* Ensure the button width is just enough for the text */
    display: block; /* Ensures the button is still block-level if needed */
    text-align: center; /* Centers the text */
}

.new-client-clear-button {
    background-color: #F0512A;
    color: white;
    padding: 8px 16px; /* Slightly increased padding for a bigger button */
    font-size: 14px; /* Increased font size */
    font-weight: bold; /* Bold text */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px; /* Space above the button */
    margin-left: 10px;
    width: auto; /* Ensure the button width is just enough for the text */
    display: block; /* Ensures the button is still block-level if needed */
    text-align: center; /* Centers the text */
}

.new-client-clear-button:hover {
    background-color: #f0a500;
}

/* Three column layout for the new client section */
.nc-three-columns {
    display: flex;
    justify-content: space-between; /* Space out the columns */
    gap: 20px; /* Spacing between columns */
    align-items: stretch; /* Stretches children to fill the container height */
}

.nc-add-additional-client {
    flex: 1; /* Each column takes equal width */
    display: flex;
    flex-direction: column; /* Align form elements in a column */
    justify-content: flex-start; /* Center the button vertically */
    margin-top: 30px;
}

.nc-vertical-divider {
    width: 1px;
    background-color: #ccc;
    margin: 20px 0; /* Top and bottom margin for a modern look */
    align-self: stretch; /* Stretches to take full available height of the container */
}

.nc-add-landlord {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the left */
    gap: 10px; /* Space between items */
    padding: 10px; /* Padding around items */
    border-bottom: 1px solid #ccc; /* Separator for visual distinction */
}

.nc-add-landlord p {
    flex: 0 0 auto;  /* Do not grow or shrink, use only the space it needs */
    white-space: nowrap;  /* Ensures text does not wrap */
}

.ct-landlord-array-table {
    flex-grow: 1;  /* Takes remaining space */
    display: flex;  /* Keeps the internal layout as flex */
    width: 100%;  /* Ensures it tries to fill the space */
}

.nc-add-tenant {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the left */
    gap: 10px; /* Space between items */
    padding: 10px; /* Padding around items */
    border-bottom: 1px solid #ccc; /* Separator for visual distinction */
}

.nc-add-tenant p {
    margin: 0; /* Remove default margin */
    flex-grow: 1; /* Allow the paragraph to take up any remaining space */
    text-align: left;
}

.ct-tenant-array-table {
    flex-grow: 1; /* Allow this div to take up remaining space if needed */
    display: flex; /* Ensures it's ready for any internal flex styling if required */
}

.nc-add-landlord h3,
.nc-add-tenant h3 {
    cursor: pointer; /* Indicates these headers are clickable */
    margin-bottom: 5px; /* Space below the header */
}

/* CSS for both Landlord and Tenant Add Button */
.nc-add-c-button {
    align-self: center; /* Center buttons horizontally within their flex container */
    cursor: pointer;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 50%;
    width: 30px; /* Slightly larger button for easier interaction */
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px; /* Larger font size for visibility */
    margin-top: 5px; /* Ensures a small space from the top element */
}

.nc-add-t-button {
    align-self: center; /* Center buttons horizontally within their flex container */
    cursor: pointer;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 50%;
    width: 30px; /* Slightly larger button for easier interaction */
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px; /* Larger font size for visibility */
    margin-top: 5px; /* Ensures a small space from the top element */
}

.nc-land-btn {
    cursor: pointer;
}

.nc-dn-btn {
    cursor: pointer;
}

.nc-la-btn,
.nc-ten-btn,
.nc-ta-btn {
    font-weight: bold;
    cursor: pointer;
}

/* Styles for the landlord table */
.nc-landlord-array-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.nc-landlord-at-table th,
.nc-landlord-at-table td {
    padding: 10px;
    border: 1px solid #ddd;
}

.nc-landlord-at-table th {
    background-color: #f2f2f2;
    text-align: left;
}

.nc-landlord-at-table td {
    text-align: left;
}

/* Align text in the "Landlord" column to the left */
.nc-landlord-at-table th:nth-child(2),
.nc-landlord-at-table td:nth-child(2) {
    text-align: left;
}

/* Button styles for landlord actions */
.nc-landlord-at-btn {
    background-color: #F0512A;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
}

.nc-landlord-at-btn:hover {
    background-color: #D03F21;
}

/* Styles for the tenant table */
.nc-tenant-array-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    margin-left: 10px;
}

.nc-tenant-at-table th,
.nc-tenant-at-table td {
    padding: 10px;
    border: 1px solid #ddd;
}

.nc-tenant-at-table th {
    background-color: #f2f2f2;
    text-align: left;
}

.nc-tenant-at-table td {
    text-align: left;
}

/* Button styles for tenant actions */
.nc-tenant-at-btn {
    background-color: #F0512A;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
}

.nc-tenant-at-btn:hover {
    background-color: #D03F21;
}

.form-group .c-title-dropdown {
    flex: 1;
    padding: 6px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-width: 300px; /* Ensures dropdown does not exceed the max width set for input fields */
    width: 100%; /* Ensures it takes full width available within the form group */
    appearance: none; /* Removes default browser styles */
    background-color: #fff; /* Sets background color to white */
    cursor: pointer; /* Changes cursor to pointer to indicate it's a dropdown */
}

/* Style for the dropdown arrow */
.form-group .c-title-dropdown::-ms-expand {
    display: none; /* Hides the default dropdown arrow for IE/Edge */
}

.form-group .c-title-dropdown::-webkit-inner-spin-button,
.form-group .c-title-dropdown::-webkit-outer-spin-button {
    -webkit-appearance: none; /* Removes default webkit styles */
    margin: 0; /* Removes default margin */
}

.nc-form-column {
    margin-right: 10em;
}

.nc-row {
    display: flex;
    justify-content: space-between; /* Aligns items to the edges of the container */
    align-items: center; /* Aligns items vertically in the center */
    width: 100%; /* Ensures the row takes full width */
    padding-right: 10px; /* Adds some padding on the right if needed for spacing */
    border-bottom: 1px solid #ccc; /* Adds a grey line below each row */
    padding-bottom: 10px; /* Adds some space below the text */
    margin-bottom: 20px; /* Adds space below the line before the next row starts */

}

.submit-button-container {
    bottom: 0;
    right: 0;
    /* Optional: Add padding or margin */
    margin-top: 2em;
    margin-left: 26em;
}


/* Responsive adjustments for better alignment */
@media (max-width: 768px) {
    .nc-add-landlord,
    .nc-add-tenant {
        flex-direction: row; /* Align horizontally on smaller screens */
        justify-content: space-between; /* Utilize space between items for a cleaner layout */
        align-items: center; /* Ensure vertical alignment is centered */
    }

    .nc-add-c-button,
    .nc-add-t-button {
        margin: 0; /* Remove margins in responsive layout */
    }
}