.invoice-details {
  display: grid;
  grid-template-columns: 150px 1fr; /* Adjusted for better alignment */
  gap: 10px;
  padding: 20px;
  align-items: center;
}

.invoice-details label {
  font-weight: bold;
  margin-bottom: 5px;
}

.invoice-input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
}

.in-num-dropdown-container {
  position: relative; /* Add this to ensure the dropdown aligns correctly */
}

.in-num-dropdown-button {
  background-color: #1D4ED8; /* Dark blue background */
  color: white; /* White text color */
  padding: 8px; /* Padding around the text */
  border: none; /* No border */
  border-radius: 4px; /* Rounded corners */
  font-size: 16px; /* Text size */
  cursor: pointer; /* Cursor indicates it's clickable */
  display: inline-flex; /* Keeps the button and dropdown in line */
  align-items: center; /* Aligns the items inside the button vertically */
  justify-content: space-between; /* Spacing between button text and dropdown icon */
  width: 100%; /* Width of the button */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  box-sizing: border-box;
}

.in-num-dropdown-button svg {
  width: 16px; /* Adjust size if necessary */
  height: 16px; /* Adjust size if necessary */
  margin-left: 8px; /* Space between the text and the icon */
}

.in-num-dropdown-content {
  display: none; /* Hidden by default */
  position: absolute; /* Positions the dropdown */
  background-color: #1E293B; /* Dark background for the dropdown */
  min-width: 200px; /* Minimum width */
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2); /* Shadow for dropdown */
  z-index: 1; /* Ensures it stays on top */
  border-radius: 0.5rem; /* Rounded corners for the dropdown */
  margin-top: 5px; /* Distance between button and dropdown */
  width: 100%; /* Make dropdown the same width as the button */
  left: 0; /* Align to the left edge of the parent container */
}

.in-num-dropdown-content.show {
  display: block; /* Show dropdown when this class is applied */
}

.in-num-dropdown-content a {
  color: white; /* White text color */
  padding: 12px 16px; /* Padding inside the dropdown */
  text-decoration: none; /* No underline */
  display: block; /* Makes the links fill the container */
  font-size: 16px; /* Text size inside the dropdown */
}

.in-num-dropdown-content a:hover {
  background-color: #374151; /* Darker background on hover */
}

.invoice-details .full-width {
  grid-column: span 2; /* Full width for VAT number */
}

.vat-toggle {
  grid-column: span 2;
  display: flex;
  align-items: center;
}

.vat-toggle input[type="checkbox"] {
  margin-right: 5px;
}

/* Ensure consistent spacing */
.invoices-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.invoices-main-content {
  display: flex;
  flex-direction: column;
  padding-left: 270px;
  padding-top: 70px;
  padding-right: 20px;
  padding-bottom: 60px;
  min-height: calc(100vh - 130px);
  box-sizing: border-box;
  margin: 20px;
  overflow-y: auto;
}

.invoices-detail-documents {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  height: auto;
  box-sizing: border-box;
  overflow-y: auto;
}

.case-detail-h3 {
  background-color: #333333;
  color: #ffffff;
  font-family: 'Arial', sans-serif;
  font-weight: 300;
  padding: 10px 20px;
  margin-top: 0;
  border-radius: 5px;
  text-align: center;
}

.invoice-generate-btn {
  background-color: #f0512b;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.invoice-generate-btn:hover {
  background-color: #e6451c;
}
