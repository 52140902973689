.case-detail-modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    backdrop-filter: blur(5px); /* Blurred backdrop */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.case-detail-modal_content {
    position: relative;
    background: white;
    padding: 20px;
    padding-bottom: 40px; /* Increased bottom padding */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    width: 50%; /* Adjusted width */
    max-width: 500px; /* Maximum width */
    max-height: 80vh; /* Limiting the maximum height */
    overflow-y: auto; /* Scrollable content */
    z-index: 1010; /* Above most content */
}

.case-detail-modal_close_button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    border: 1px solid black; /* Square border */
    background: none;
    color: black;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.case-detail-modal_close_button:hover {
    background: none; /* No change on hover */
}

.case-detail-modal_input {
    display: block;
    width: calc(100% - 20px); /* Full width minus padding */
    padding: 10px;
    margin-bottom: 20px; /* Space between inputs */
    border: none;
    border-bottom: 1px solid #ccc; /* Underline style */
    font-size: 1rem; /* Standard font size */
}

.case-detail-modal_input::placeholder {
    color: #808080; /* Placeholder text color */
}

.case-detail-modal_submit_button {
    background-color: #f0512b; /* Bright orange */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    width: auto; /* Adjust width to content */
    border-radius: 5px; /* Rounded corners for the button */
}

.case-detail-modal_submit_button:hover {
    background-color: #e6451c; /* Slightly darker on hover */
}
