.case-detail-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Main content container using grid */
.case-detail-main-content {
  display: flex;
  flex-direction: column;
  padding-left: 270px; /* Space for the fixed sidebar */
  padding-top: 70px;   /* Space for the fixed header */
  padding-right: 20px; /* Extra space on the right */
  padding-bottom: 60px; /* Space for the fixed footer */
  min-height: calc(100vh - 130px); /* Full height minus the header and footer */
  box-sizing: border-box;
  margin: 20px;
  overflow-y: auto;
}

/* Apply Masonry-like behavior with grid */
.case-detail-content-area {
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* Create a 5-column grid */
  grid-auto-rows: auto; /* Adjust row heights based on content */
  gap: 20px; /* Space between grid items */
}

/* Case Details - occupies columns 1 to 3 */
.case-detail-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  grid-column: 1 / span 3; /* Start at column 1, span 2 columns */
  grid-row: 1; /* First row */
  box-sizing: border-box;
  text-align: left;
  position: relative;
}

/* Customer Details - occupies columns 1 to 3 */
.case-detail-invoices {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  grid-column: 1 / span 3; 
  grid-row: 2; /* Second row */
  box-sizing: border-box;
  text-align: left;
}

/* Content - occupies columns 4 to 7 */
.case-detail-documents {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  grid-column: 4 / span 7; 
  grid-row: 1 / span 2; /* Span across both rows */
  height: auto;
  box-sizing: border-box;
  overflow-y: auto; /* Scroll if content overflows */
  position: relative;
}

/* Tenant Info Section - occupies columns 1 to 2 */
.case-detail-tenant-info {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  grid-column: 1 / span 3;  /* Start at column 1, span 2 columns */
  grid-row: 3; /* Place in the second row below the invoices section */
  box-sizing: border-box;
  text-align: left;
}

.case-detail-tenant-info h3 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #333; /* Darker text for better readability */
}

/* Updated styling for the label and data to align them horizontally */
.case-detail-ti-label {
  font-weight: bold;
  color: #555; /* Distinct color for labels */
  display: inline-block; /* Display inline to align next to the data */
  margin-right: 10px; /* Space between label and data */
  width: 150px; /* Fixed width for consistent alignment */
}

.case-detail-ti-data {
  display: inline-block; /* Display inline to align next to the label */
  padding: 5px 10px; /* Padding for spacing */
  border: 1px solid #000; /* Thin black border around the data */
  border-radius: 4px; /* Slightly rounded corners for the box */
  color: #000; /* Text color for data */
  background-color: #f9f9f9; /* Light background for better readability */
  min-width: 100px; /* Set a fixed minimum width for consistency */
  max-width: 300px; /* Limit maximum width to maintain alignment */
  width: auto; /* Flexibly adjust width based on content */
  box-sizing: border-box; /* Ensure padding is included in the width */
}

/* Additional Landlord Info Section - occupies columns 3 to 4 */
.case-detail-addlandlord-info {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  grid-column: 4 / span 2;  /* Start at column 3, span 2 columns */
  grid-row: 3; /* Place in the second row below the invoices section */
  box-sizing: border-box;
  text-align: left;
}

/* Additional Tenant Info Section - occupies columns 5 to 7 */
.case-detail-addtenant-info {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  grid-column: 6 / span 5;  /* Start at column 5, span 3 columns */
  grid-row: 3; /* Place in the second row below the invoices section */
  box-sizing: border-box;
  text-align: left;
}

/* Additional Placeholder Styling */
.additional-landlord-placeholder,
.additional-tenant-placeholder {
  margin-top: 10px;
}

.empty-box {
  display: inline-block;
  padding: 5px 10px; /* Add padding to give some size to the empty box */
  border: 1px dashed #ccc; /* Dashed border to indicate an empty field */
  border-radius: 4px; /* Rounded corners */
  min-width: 150px; /* Fixed size for consistency */
  height: 20px; /* Height to represent the empty box */
  background-color: #f9f9f9; /* Light background to match other fields */
}


/* Invoice Display */
.case-detail-comments {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  grid-column: 1 / span 7;
  box-sizing: border-box;
  height: auto;
  overflow-y: auto; /* Scroll if content overflows */
}

/* Position the dropdown button at the top-right corner */
.case-detail-dropdown-button {
  position: absolute;
  top: 10px;   /* Adjust the top spacing as needed */
  right: 10px; /* Adjust the right spacing as needed */
  background: none; /* No background for a clean look */
  border: none;  /* No border for a clean look */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 24px; /* Size of the dots */
  color: #333; /* Color of the dots, match with container or as desired */
  width: 30px; /* Control the width of the button */
  height: 30px; /* Control the height of the button */
  display: flex;
  align-items: center;
  justify-content: center;
}

.case-detail-dropdown-menu {
  position: absolute;
  top: 40px; /* Ensures it opens downwards outside the container */
  right: 0; /* Aligns with the right edge of the dropdown button */
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  width: 150px;
  z-index: 1000;
  padding: 5px 0; /* Padding to add space inside dropdown */
}

.case-detail-dropdown-menu button {
  width: 100%; /* Full width of the dropdown */
  padding: 8px 16px; /* Padding for spacing */
  text-align: left; /* Align text to the left */
  background: none; /* No background initially */
  border: none; /* No borders */
  outline: none; /* Remove outline */
  cursor: pointer; /* Pointer cursor on hover */
  color: #333; /* Text color */
}

.case-detail-dropdown-menu button:hover {
  background-color: #f7f7f7; /* Light background on hover for visibility */
}

.case-detail-h3 {
  background-color: #333333; /* Dark grey background */
  color: #ffffff; /* White font color */
  font-family: 'Arial', sans-serif; /* A modern font, you can choose a different one */
  font-weight: 300; /* Thin font */
  padding: 10px 20px; /* Add some padding for spacing */
  margin-top: 0; /* Adjust margin if needed */
  border-radius: 5px; /* Slightly round corners for a modern look */
  text-align: center; /* Center the text */
}

.generate-documents-btn {
  background-color: #f0512b; /* Bright orange color */
  color: white; /* White text */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px 20px; /* Padding for size */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth hover transition */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  width: auto;
  margin-right: 10px; /* Add space to the right of the button */
}

/* Hover effect */
.generate-documents-btn:hover {
  background-color: #e6451c; /* Slightly darker shade of orange */
}

/* CSS for Files Table */
.files-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.files-table th, .files-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.files-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.files-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.case-detail-additional-landlord-disp {
  margin-top: 10px; /* Match the spacing of other fields */
  margin-bottom: 10px; /* Consistent bottom spacing */
}

.case-detail-additional-landlord-disp p {
  margin-bottom: 5px; /* Slight space below the heading if needed */
}

.case-detail-additional-landlord-disp ul {
  padding-left: 20px; /* Indent the list to align with other text */
  list-style-type: none; /* Remove bullet points for a cleaner look */
}

.case-detail-additional-landlord-disp li {
  margin-bottom: 5px; /* Space between list items */
}

/* Container for the comments section */
.case-detail-comments-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  grid-column: 1 / span 10;
  margin-top: 20px;
  text-align: left;  /* Align all text to the left */
}

/* Title styling */
.case-detail-comments-title {
  color: #333;
  margin-bottom: 20px; /* Space below the title */
  padding: 10px;
  border-radius: 4px;
  font-size: 1.1rem;  /* Increase font size for better readability */
}

/* Timeline container */
.case-detail-comments-timeline {
  position: relative;
  border-left: 2px solid #ccc;
  margin-left: 20px;
  padding-left: 20px;
}

/* Entry for each comment */
.case-detail-comments-entry {
  position: relative;
  margin-bottom: 30px;
}

/* Dot and line for timeline */
.case-detail-comments-dot-line {
  width: 10px;
  height: 10px;
  background-color: #667;
  border-radius: 50%;
  position: absolute;
  left: -26px; /* Adjust based on actual border line */
  top: 0;
}

/* Individual comment styling */
.case-detail-comments-comment {
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

/* Input area for new comments */
.case-detail-comments-input {
  display: flex;
  margin-top: 20px;
}

/* Text input for writing comments */
.case-detail-comments-text-input {
  flex-grow: 1;
  margin-right: 10px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Button for submitting comments */
.case-detail-comments-submit-btn {
  background-color: #f0512b; /* Green color */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.case-detail-comments-submit-btn:hover {
  background-color: #e6451c; /* Darker green */
}

/* Metadata for comments (user and date) */
.comment-meta {
  font-size: 0.85rem;  /* Smaller font size */
  font-weight: bold;  /* Bold font for emphasis */
  color: #667;  /* Optional: change color to distinguish from comment text */
}

.case-detail-field {
  margin-bottom: 15px; /* Space between each field */
}

.case-detail-label {
  display: block; /* Ensures the label is on its own line */
  margin-bottom: 5px; /* Space between label and input */
  color: #333; /* Label text color */
  font-weight: bold; /* Bold font for labels */
}

.case-detail-input {
  width: 100%; /* Full width of its container */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.case-detail-edit-submit-btn {
  background-color: #f0512b; /* Example green background */
  color: white; /* White text */
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px; /* Space above the button */
}

.case-detail-edit-submit-btn:hover {
  background-color: #e6451c; /* Darker green on hover */
}

.document-type-dropdown {
  width: 250px; /* Increased width to better accommodate the text */
  margin-right: 10px; /* Keeps space between the dropdown and the button */
  padding: 8px 20px; /* Adjusted padding to better match the height of the button */
  font-size: 16px; /* Maintains font size to match the button text */
  border: 1px solid #ccc; /* Keeps the border color consistent with the design */
  border-radius: 5px; /* Rounded corners to match the button */
  background-color: #fff; /* Matches the button's background color */
  color: #333; /* Text color to match the button */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow to match the button */
  cursor: pointer; /* Indicates it's clickable */
}

.delete-documents-btn {
  background-color: #f0512b; /* Bright orange color */
  color: white; /* White text */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px 20px; /* Padding for size */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth hover transition */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  width: auto;
}

/* Hover effect */
.delete-documents-btn:hover {
  background-color: #e6451c; /* Slightly darker shade of orange */
}

/* Settings Button */
.settings-button {
  position: absolute;
  top: 10px; /* Adjust this value to align top */
  right: 10px; /* Adjust this value to align to the right */
  background: none; /* No background for a clean look */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 12px; /* Size of the dots */
  color: #333; /* Color of the dots, match with container or as desired */
  width: auto; /* Control the width of the button */
  height: auto; /* Control the height of the button */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px; /* Adjust padding as needed */
  border: 2px solid black; /* Black border */
  box-shadow: none; /* Remove any box-shadow if previously set */
  border-radius: 0;
}

.drag-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  border: dashed 2px black; /* Change border color to black */
  display: flex;
  justify-content: center;
  align-items: center;
  color: black; /* Change text color to black */
  font-size: 24px;
}

.upload-progress-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  border: dashed 2px black; /* You can adjust this to fit your design */
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 24px;
  z-index: 100; /* Ensure it stays on top */
}











