/* src/components/DashboardHeader.css */

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #e7e7e7;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 50px; /* Adjusted height */
}

.header-left {
  display: flex;
  align-items: center;
}

.dashboard-logo {
  height: 40px; /* Specific height for the dashboard logo */
  width: 40px; /* Specific width for the dashboard logo */
  margin-right: 10px;
}

.header-center {
  display: flex;
  flex-grow: 1;
  justify-content: right;
  align-items: center;
  position: relative;
}

.search-icon {
  cursor: pointer;
}

.header-right {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 20px; /* Ensure visibility on larger screens */
}

.profile-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-right: 20px; /* Added margin to bring it further inside */
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 50px; /* Aligns below the header */
  right: 0; /* Aligns to the right under the profile icon */
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 150px;
  border-radius: 4px;
  padding: 10px 0; /* Adds padding around the links */
}

.profile-icon:hover .dropdown-content {
  display: block;
}

.dropdown-content {
  display: block;
  position: absolute;
  top: 50px;
  right: 0;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 150px;
  border-radius: 4px;
  padding: 0; /* Remove padding to make it more like links */
}

.dropdown-content button {
  background: none;
  color: black;
  padding: 12px 0; /* Adjust padding to center text vertically */
  border: none;
  width: 100%;
  text-align: center; /* Center text horizontally */
  cursor: pointer;
  font-size: 16px;
}

.dropdown-content button:hover {
  background-color: #f1f1f1;
}

.dropdown-content button:focus {
  outline: none;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Vertical separator */
.header-left::after,
.header-right::before {
  content: "";
  display: inline-block;
  width: 1px;
  height: 30px;
  background-color: #e7e7e7;
  margin: 0 10px;
}

.header-left::after {
  height: 30px;
}

.header-right::before {
  height: 30px;
}

@media (max-width: 768px) {
  .dashboard-header {
    flex-direction: row;
    justify-content: space-between;
  }

  .search-input {
    width: calc(100% - 70px); /* Adjust width considering padding and margins */
    max-width: none;
    display: block;
    margin-right: 20px; /* Ensure it doesn't overlap with the profile icon */
  }

  .profile-icon {
    margin-right: 20px; /* Ensure visibility on mobile screens */
  }
}

