/* src/SignIn.css */
.signin-app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
}

.signin-signin-container {
    max-width: 400px;
    width: 100%;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px; /* Added bottom margin */
}


.signin-role-selector {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.signin-role-selector label {
    font-size: 16px;
    color: #333;
}

.signin-input[type="email"],
.signin-input[type="password"] {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.signin-button {
    width: 50%;
    padding: 10px;
    background-color: #f0512b;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

.signin-a {
    color: #007bff;
    text-decoration: none;
}

.signin-a:hover {
    text-decoration: underline;
}

.signin-terms {
    font-size: 12px;
    color: #666;
    margin-top: 20px;
}

.signin-footer {
    font-size: 14px;
    color: #aaa;
    margin-top: 40px;
}
