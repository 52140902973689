/* Archive.css */

/* The overall container */
.archive-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Ensure it takes the full height of the viewport */
  }
  
  .archive-main-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Ensures it fills the space between the header (if any) and the footer */
    overflow-y: auto; /* Enables scrolling within this container */
    margin-top: 60px; /* Maintain existing top margin to account for a fixed header */
    padding-bottom: 60px; /* Ensures there is a space above the footer */
  }
  
  /* Reset any global body styling from App.css */
  body {
    margin: 0;
    font-family: Arial, sans-serif; /* Retain the font from App.css */
    background-color: #f4f4f4; /* Use the same background color as App.css */
    display: block; /* Change from flex to block */
    justify-content: unset; /* Remove flexbox centering */
    align-items: unset; /* Remove flexbox centering */
    height: auto; /* Allow the height to be determined by content */
    flex-direction: column; /* Ensure elements flow in a column */
  }
  
  .sidebar {
    width: 250px;
    background-color: #343a40;
    color: #fff;
    padding: 10px;
    height: calc(100vh - 120px); /* Adjust height considering the header and footer height */
    position: fixed;
    top: 60px; /* Adjust top position considering the header height */
    left: 0;
    overflow-y: auto; /* Add scroll if content overflows */
  }
  
  .archive-content-area {
    margin-left: 250px; /* Ensure content starts after the sidebar */
    flex: 1;
    padding: 20px;
  }
  
  /* Style for the Archived Cases section */
  .archive-cases {
    background-color: #fff;
    padding: 20px;
    margin-top: 10px;
    margin-left: 20px; /* Adjust this value to add space between the sidebar and the box */
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 100%; /* Make it responsive */
    width: calc(100% - 25px); /* Ensure it adjusts based on the available space */
    text-align: left; /* Ensure content is aligned to the left */
    box-sizing: border-box; /* Include padding in the width calculation */
    margin-bottom: 20px;
  }
  
  /* Header for the Archived Cases section with search bar */
  .archive-cases-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .archive-search-bar {
    width: 250px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 10px; /* Add minimal space between the search bar and other elements */
  }
  
  .archive-case-row-delete {
    background-color: #f0512b;
    color: white;
    padding: 8px 16px; /* Adjust padding for smaller size */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px; /* Reduce font size */
    width: auto; /* Ensure it's not stretched */
    margin-left: 5px;
  }
  
  .archive-case-row-delete:hover {
    background-color: #e6451c;
  }

  .archive-case-row-unarchive {
    background-color: #f0512b;
    color: white;
    padding: 8px 16px; /* Adjust padding for smaller size */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px; /* Reduce font size */
    width: auto; /* Ensure it's not stretched */
    margin-left: 5px;
  }
  
  .archive-case-row-unarchive:hover {
    background-color: #e6451c;
  }
  
  .archive-cases-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .archive-cases-table th,
  .archive-cases-table td {
    padding: 8px 15px; /* Ensure uniform padding for consistency in alignment */
    text-align: left; /* Align text to the left */
    vertical-align: middle; /* Center content vertically */
    border-bottom: 1px solid #ddd; /* Maintain border styling */
  }
  
  .archive-cases-table th {
    text-align: left; /* Align text left for headers */
    background-color: #343a40;
    color: white;
    padding: 8px 15px;
  }
  
  .archive-cases-table td {
    text-align: left; /* Ensure text in cells is left-aligned */
    padding: 8px 15px;
    vertical-align: middle; /* Center content vertically */
  }
  
  /* Style for the checkbox column to ensure it does not offset other content */
  .archive-cases-table th:first-child,
  .archive-cases-table td:first-child {
    text-align: left; /* Align checkboxes to the left */
    width: 50px; /* Fix the width of the checkbox column to ensure alignment */
  }
  
  .archive-cases-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .archive-cases-table tr:hover {
    background-color: #ddd;
  }
  
  .archive-header-content,
  .archive-row-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  /* This ensures the row-content does not expand unduly */
  .archive-header-content > th,
  .archive-row-content > span {
    flex: 1;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  td input[type='checkbox'] {
    margin-right: 10px;
    cursor: pointer;
  }
  
  .archive-header-content > th.short,
  .archive-row-content > span.short {
    flex-grow: 0.5; /* Allows minimal growth */
  }
  
  /* Responsive adjustments for smaller screens */
  @media (max-width: 768px) {
    .archive-content-area {
      margin-left: 0;
      margin-top: 60px; /* Ensure content starts below the header */
    }
  
    .archive-cases {
      margin-left: 10px; /* Reduce margin on smaller screens */
      padding: 15px;
    }
  
    .archive-cases-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .archive-search-bar {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .archive-case-row-delete {
      width: 100%;
      text-align: center;
    }
  
    .footer {
      width: 100%;
      margin-left: 0;
    }
  }
  