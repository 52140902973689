/* src/components/MainFooter.css */

.main-footer {
    padding: 20px;
    background-color: #000000;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0; /* Ensure it starts from the left */
    width: 100%;
    z-index: 1000;
    height: 5px;
  }
  
  @media (max-width: 768px) {
    .main-footer {
      position: relative;
    }
  }
  