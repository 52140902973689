.signin-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px; /* Adjust as needed */
    background-color: transparent; /* Changed from white to transparent */
    color: grey;
    text-align: center;
    padding: 10px 0;
    font-size: 14px;
}