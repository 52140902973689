/* src/pages/Dashboard.css */

/* The overall container */
.dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Ensure it takes the full height of the viewport */
}

.main-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Ensures it fills the space between the header (if any) and the footer */
  overflow-y: auto; /* Enables scrolling within this container */
  margin-top: 60px; /* Maintain existing top margin to account for a fixed header */
  padding-bottom: 60px; /* Ensures there is a space above the footer */
}

/* Reset any global body styling from App.css */
body {
  margin: 0;
  font-family: Arial, sans-serif; /* Retain the font from App.css */
  background-color: #f4f4f4; /* Use the same background color as App.css */
  display: block; /* Change from flex to block */
  justify-content: unset; /* Remove flexbox centering */
  align-items: unset; /* Remove flexbox centering */
  height: auto; /* Allow the height to be determined by content */
  flex-direction: column; /* Ensure elements flow in a column */
}

.sidebar {
  width: 250px;
  background-color: #343a40;
  color: #fff;
  padding: 10px;
  height: calc(100vh - 120px); /* Adjust height considering the header and footer height */
  position: fixed;
  top: 60px; /* Adjust top position considering the header height */
  left: 0;
  overflow-y: auto; /* Add scroll if content overflows */
}

.content-area {
  margin-left: 250px; /* Ensure content starts after the sidebar */
  flex: 1;
  padding: 20px;
}

/* Style for the Recent Cases section */
.recent-cases {
  background-color: #fff;
  padding: 20px;
  margin-top: 10px;
  margin-left: 20px; /* Adjust this value to add space between the sidebar and the box */
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 100%; /* Make it responsive */
  width: calc(100% - 25px); /* Ensure it adjusts based on the available space */
  text-align: left; /* Ensure content is aligned to the left */
  box-sizing: border-box; /* Include padding in the width calculation */
  margin-bottom: 20px;
}

/* Header for the Recent Cases section with search bar and create button */
.recent-cases-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-and-create {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align items to the right */
}

.search-bar {
  width: 250px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 10px; /* Add minimal space between the search bar and the button */
}

/* Specific styles for the Create Case button in Dashboard.css */
.recent-cases .create-case-btn {
  background-color: #f0512b;
  color: white;
  padding: 8px 16px; /* Adjust padding for smaller size */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px; /* Reduce font size */
  width: auto; /* Ensure it's not stretched */
}

.recent-cases .create-case-btn:hover {
  background-color: #e6451c;
}

/* Specific styles for the Create Delete button in Dashboard.css */
.case-row-delete {
  background-color: #f0512b;
  color: white;
  padding: 8px 16px; /* Adjust padding for smaller size */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px; /* Reduce font size */
  width: auto; /* Ensure it's not stretched */
  margin-left: 5px;
}

.case-row-delete:hover {
  background-color: #e6451c;
}

/* Specific styles for the Create Archive button in Dashboard.css */
.case-row-archive {
  background-color: #f0512b;
  color: white;
  padding: 8px 16px; /* Adjust padding for smaller size */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px; /* Reduce font size */
  width: auto; /* Ensure it's not stretched */
  margin-left: 5px;
}

.case-row-archive:hover {
  background-color: #e6451c;
}

.cases-table {
  width: 100%;
  border-collapse: collapse;
}

.cases-table th, .cases-table td {
  padding: 8px 15px; /* Ensure uniform padding for consistency in alignment */
  text-align: left; /* Align text to the left */
  vertical-align: middle; /* Center content vertically */
  border-bottom: 1px solid #ddd; /* Maintain border styling */
}

.cases-table th {
  text-align: left; /* Align text left for headers */
  background-color: #343a40;
  color: white;
  padding: 8px 15px;
}

.cases-table td {
  text-align: left; /* Ensure text in cells is left-aligned */
  padding: 8px 15px;
  vertical-align: middle; /* Center content vertically */
}

/* Style for the checkbox column to ensure it does not offset other content */
.cases-table th:first-child,
.cases-table td:first-child {
  text-align: left; /* Align checkboxes to the left */
  width: 50px; /* Fix the width of the checkbox column to ensure alignment */
}

.cases-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.cases-table tr:hover {
  background-color: #ddd;
}

.header-content, .row-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* This ensures the row-content does not expand unduly */
.header-content > th, .row-content > span {
  flex: 1;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

td input[type="checkbox"] {
  margin-right: 10px;
  cursor: pointer;
}

.header-content > th.short, .row-content > span.short {
  flex-grow: 0.5; /* Allows minimal growth */
}

/* Optional: To make the recent-cases div responsive */
@media (max-width: 768px) {
  .recent-cases {
    margin-left: 10px; /* Reduce margin on smaller screens */
    padding: 15px;
  }

  .recent-cases-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-and-create {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-bar {
    width: 100%;
    margin-bottom: 10px;
  }

  .create-case-btn {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .content-area {
    margin-left: 0;
    margin-top: 60px; /* Ensure content starts below the header */
  }

  .footer {
    width: 100%;
    margin-left: 0;
  }
}
