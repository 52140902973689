.iminfo-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.iminfo-main-content {
  display: flex;
  flex-direction: column;
  padding-left: 270px;
  padding-top: 70px;
  padding-right: 20px;
  padding-bottom: 60px;
  min-height: calc(100vh - 130px);
  box-sizing: border-box;
  margin: 20px;
  overflow-y: auto;
}

.iminfo-content-area {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 20px;
}

.iminfo-detail-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  grid-column: 1 / span 2;
}

.iminfo-documents {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  grid-column: 3 / span 5;
  grid-row: 1 / span 2;  /* This will allow the documents div to grow and align */
  height: auto;
  overflow-y: auto;
}

/* Invoice Total */
.iminfo-total {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  grid-column: 1 / span 2;
  box-sizing: border-box;
  height: auto;
}

/* Invoice Display */
.iminfo-display {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  grid-column: 1 / span 7;
  box-sizing: border-box;
  height: auto;
}

/* Styles for the header section of the invoice display */
.iminfo-display-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iminfo-id-h3 {
  margin: 0;  /* Adjust margin as necessary */
}

.iminfo-add-fee-btn {
  padding: 8px 16px;
  background-color: #f0512b; /* Green background for the button */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 25%; /* Adjust width as necessary */
  max-width: 200px; /* Increase max width to accommodate longer text */
  white-space: nowrap; /* Prevents text from wrapping */
}

.iminfo-add-fee-btn:hover {
  background-color: #e6451c; /* Darker shade of green on hover */
}

.iminfo-id-table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Arial', sans-serif;
}

.iminfo-id-table thead {
  background-color: #333333; /* Dark grey background for header */
  color: #f2f2f2; /* Light grey/white text */
  font-weight: bold;
}

.iminfo-id-table th,
.iminfo-id-table td {
  padding: 12px 15px;
  border: 1px solid #bfbfbf; /* Light grey borders for a subtle yet professional touch */
  text-align: left;
}

.iminfo-id-table tbody tr:nth-child(even) {
  background-color: #f7f7f7; /* Very light grey background for even rows */
}

.iminfo-id-table tbody tr:nth-child(odd) {
  background-color: #ffffff; /* White background for odd rows */
}

.iminfo-id-table tbody tr:hover {
  background-color: #e6e6e6; /* Slight hover effect for rows */
}

.iminfo-id-table th {
  text-transform: none;  /* Changed from uppercase to default */
  letter-spacing: normal;  /* Adjusting to normal to reduce spacing */
  font-size: 0.9em;  /* Smaller font size for header */
}

.iminfo-id-table td {
  color: #333333; /* Dark grey text for body */
}

.iminfo-id-table tbody tr td:first-child {
  font-weight: bold;
}

.iminfo-id-h3 {
  color: #333333; /* Heading color to match the table theme */
  margin-bottom: 15px;
  font-size: 1.2em;
  border-bottom: 2px solid #bfbfbf; /* Professional border for the heading */
  padding-bottom: 5px;
}

.iminfo-h3 {
  background-color: #333333;
  color: #ffffff;
  padding: 10px 20px;
  margin-top: 0;
  border-radius: 5px;
  text-align: center;
}

.iminfo-generate-documents-btn {
  background-color: #f0512b;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 40%; /* Adjust width as necessary */
  max-width: 250px; /* Increase max width to accommodate longer text */
  white-space: nowrap; /* Prevents text from wrapping */
}

.iminfo-generate-documents-btn:hover {
  background-color: #e6451c;
}

.iminfo-files-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.iminfo-files-table th,
.iminfo-files-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.iminfo-files-table th {
  background-color: #f2f2f2;
}

.iminfo-files-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.iminfo-files-table tr:hover {
  background-color: #f1f1f1;
}

.iminfo-files-table th {
  background-color: #333333;
  color: white;
}

.iminfo-files-table td a {
  color: #333333;
  text-decoration: none;
}

.iminfo-files-table td a:hover {
  text-decoration: underline;
}

.iminfo-success-message {
  color: #333333;
  font-weight: bold;
  margin-top: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .iminfo-content-area {
    grid-template-columns: 1fr;
  }

  .iminfo-detail-container,
  .iminfo-documents,
  .iminfo-features {
    grid-column: 1 / span 1;
  }
}

